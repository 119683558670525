import $ from 'jquery';

const tabs = document.querySelector('[data-tabs="tabs"]');
const items = [...document.querySelectorAll('[data-tabs-item]')];

if (tabs) {
  tabs.addEventListener('change', (event) => {
    const tabValue = event.target.value;

    items.forEach((item) => {
      const itemId = item.getAttribute('data-tabs-item');
      if (tabValue === itemId) {
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }
    });
  });
}

const videoBg = document.querySelectorAll('[data-video-background]');

if (videoBg) {
  videoBg.forEach((elem) => {
    elem.addEventListener('click', () => {
      elem.classList.add('d-none');
      const videoBlock = elem.closest('[data-video-block]');
      const videoShow = videoBlock.querySelector('[data-video-show]');
      const srcVideoShow = videoShow.getAttribute('data-video-show-src');

      if (srcVideoShow) {
        const iframeVideo = videoBlock.getElementsByTagName('iframe');

        if (iframeVideo && !iframeVideo.length) {
          const initIframeVideo = `<iframe class="video" width="100%" src="${srcVideoShow}?autoplay=1" loading="lazy" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
          videoShow.insertAdjacentHTML('afterbegin', initIframeVideo);
          videoShow.classList.add('d-block');
        }
      } else {
        const video = videoBlock.getElementsByTagName('iframe');
        const videoSrc = video[0].getAttribute('src');
        video[0].setAttribute('src', `${videoSrc}?autoplay=1`);
        videoShow.classList.add('d-block');
      }
    });
  });
}

$('[data-lenses-care-slider]').slick({
  slidesToShow   : 3,
  slidesToScroll : 3,

  nextArrow : $('[data-lenses-care-slider-next]'),
  prevArrow : $('[data-lenses-care-slider-prev]'),

  dots          : true,
  arrows        : true,
  variableWidth : false,
  infinite      : false,
  responsive    : [
    {
      breakpoint : 1280,
      settings   : {
        slidesToShow   : 3,
        slidesToScroll : 3,
        dots           : true,
        arrows         : false,
        variableWidth  : false,
        infinite       : false
      }
    },
    {
      breakpoint : 1024,
      settings   : {
        slidesToShow   : 2,
        slidesToScroll : 2,
        dots           : true,
        arrows         : false,
        variableWidth  : false,
        infinite       : false
      }
    },
    {
      breakpoint : 768,
      settings   : {
        slidesToShow   : 1,
        slidesToScroll : 1,
        dots           : true,
        arrows         : false,
        variableWidth  : true,
        infinite       : false
      }
    }
  ]
});
