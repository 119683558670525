import 'slick-carousel';
import '../../../svg-icons/close-window.svg';
import { initRVideoVK, initRVideoYoutube } from '../../redesign/bundles/product-landings/partials/r-video-block';

const elDetailPageMaterial = document.querySelector('[data-id-detail-material-page]');
const elUserDetailMaterial = document.querySelector('[data-status-user-detail-material]');
const isUrlShowPage = window.location.href.indexOf('utm_source') >= 0;

if (elDetailPageMaterial) {
  const typeUser = elUserDetailMaterial.getAttribute('data-status-user-detail-material');

  // Добавляем класс, чтобы стили для авторизации были только на деталках
  document.querySelector('body').classList.add('page-detail-material');

  const btnOpenTestMaterial = document.querySelectorAll('[data-open-test-detail-material]');
  if (btnOpenTestMaterial) {
    btnOpenTestMaterial.forEach((elem) => {
      elem.addEventListener('click', () => {
        const popupTestMaterial = document.querySelector('[data-popup-test-detail-material-page]');
        document.dispatchEvent(new CustomEvent('test-detail-material:show'));

        if (popupTestMaterial) {
          popupTestMaterial.classList.add('active');
        }
      });
    });
  }

  // Воспроизведение видео
  document.addEventListener('DOMContentLoaded', () => {
    const materialVideo = document.querySelector('[data-type-detail-material="video"]');

    if (!materialVideo) {
      return;
    }

    if (materialVideo.getAttribute('data-r-video-youtube') && (typeUser === 'specialist' || isUrlShowPage)) {
      initRVideoYoutube(true);
    }

    if (materialVideo.getAttribute('data-r-video-vk') && (typeUser === 'specialist' || isUrlShowPage)) {
      initRVideoVK(true);
    }
  });

  const timeCodeButtons = document.querySelectorAll('[data-video-detail-material="timecode"]');

  if (timeCodeButtons.length) {
    [...timeCodeButtons].forEach((item) => {
      const button = item;
      const timestamp = button.hasAttribute('data-value') ? button.getAttribute('data-value') : 0;
      const hours = Math.floor(timestamp / 60 / 60);
      const minutes = Math.floor(timestamp / 60) - (hours * 60);
      const seconds = timestamp % 60;
      const formattedTime = [];
      if (hours) {
        formattedTime.push(hours.toString().padStart(2, '0'));
      }
      formattedTime.push(minutes.toString().padStart(2, '0'));
      formattedTime.push(seconds.toString().padStart(2, '0'));
      button.innerText = formattedTime.join(':');
    });
  }
}

if (elUserDetailMaterial) {
  const typeUser = elUserDetailMaterial.getAttribute('data-status-user-detail-material');
  const typeMaterial = elUserDetailMaterial.getAttribute('data-type-detail-material');
  const authConsumer = document.querySelector('[data-auth-for-consumer]');

  if (typeUser === 'specialist' || typeMaterial === 'news') {
    elUserDetailMaterial.classList.add('active-material');
  }
  if (typeUser === 'consumer' && authConsumer && typeMaterial !== 'news') {
    // Выводим попап
    document.querySelector('[data-info-for-consumer]').classList.add('open-popup');
  }
  if (typeUser === 'noAuth' && authConsumer && typeMaterial !== 'news') {
    document.querySelector('[data-auth-for-consumer]').classList.add('open-popup');
  }

  const authBtn = document.querySelector('[data-open-auth-consumer]');
  const authReg = document.querySelector('[data-open-reg-consumer]');

  if (authBtn) {
    authBtn.addEventListener('click', () => {
      document.querySelector('[data-auth-for-consumer]').classList.remove('open-popup');
      document.dispatchEvent(new CustomEvent('auth:show'));
    });
  }

  if (authReg) {
    authReg.addEventListener('click', () => {
      document.querySelector('[data-auth-for-consumer]').classList.remove('open-popup');
      document.dispatchEvent(new CustomEvent('auth:reg'));
    });
  }
}
