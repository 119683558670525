import $ from 'jquery';

$("[data-team-slider='']").slick({
  // slidesToShow   : 3,
  // slidesToScroll : 1,

  dots: true,

  nextArrow : $('[data-team-slider-nextBtn]'),
  prevArrow : $('[data-team-slider-prevBtn]'),

  responsive: [
    {
      breakpoint : 0,
      settings   : {
        slidesToShow : 1,
        arrows       : false
      }
    },

    {
      breakpoint : 768 - 1,
      settings   : {
        slidesToShow : 2,
        arrows       : true
      }
    },

    {
      breakpoint : 1440 - 1,
      settings   : {
        slidesToShow: 3
      }
    }
  ],

  mobileFirst: true
});
