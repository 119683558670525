const tagsWrapper = document.querySelector('[data-faq-group-filters="tags"]');
const sections = document.querySelectorAll('[data-faq-group-filters="section"]');

const findEnabledTags = () => [...tagsWrapper.querySelectorAll('.tag__state:checked')];
const enabledTagsValues = () => findEnabledTags().map((tag) => tag.value);

const onTagsChange = () => {
  const values = enabledTagsValues();

  sections.forEach((section) => {
    const id = section.getAttribute('data-id');

    if (!values.length) {
      section.removeAttribute('hidden');
      return;
    }

    if (values.includes(id)) {
      section.removeAttribute('hidden');
    } else {
      section.setAttribute('hidden', '');
    }
  });
};

const handleFaqGroupToggle = (toggle) => toggle.classList.toggle('is-active');

const init = () => {
  tagsWrapper.addEventListener('change', onTagsChange);
  onTagsChange();

  document.body.addEventListener('click', (e) => {
    const isOpenFilters = (
      e.target.getAttribute('data-faq-group-filters') === 'toggle'
      || e.target.closest('[data-faq-group-filters="toggle"]')
    );

    if (e.target && isOpenFilters) {
      handleFaqGroupToggle(e.target.closest('[data-faq-group-filters="toggle"]'));
    }
  });
};

if (tagsWrapper && sections.length) {
  init();
}
