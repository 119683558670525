import 'slick-carousel';

// import boxberry from './plugins/boxberry';
// eslint-disable-next-line
import Selectr from 'mobius1-selectr';

import './utils/styles';
import './utils/sprite';

import './redesign/utils/viewport-dimensions';
import './redesign/pages/mify-i-fakty';

import './components/specialists/pro-page-filters';
import './components/specialists/pro-page-video';

import './components/faq/faq-group-filters';
import './components/scroll/scroll-to';

import './pages/club/club-privilege-slider';
// import './pages/club/invite-friend';
import './pages/club/index';

import './pages/team';

/* TODO: ПОСЛЯЕ РЕДИЗАЙНА СТРАНИЦ проверить, что больше не используется и удалить подключение */
import './pages/wearing-and-care';
import './pages/wearing-and-care/faq';

import './pages/lenses/myths-and-facts';
import './pages/lenses/l-tabs';

import './pages/detail-materials/detail-materials';

import { StickySidebar } from './pages/detail-materials/sticky-sidebar';
import { BannerDetail } from './pages/detail-materials/banners';
import { ArticleContentGenerator } from './pages/detail-materials/article-content-generator';

import './pages/locator/city-locator';

// eslint-disable-next-line max-statements
window.addEventListener('DOMContentLoaded', fire);

// Init StickySidebar, Banners, ArticleContent //

// Form of LandingPage Try Precision
const formLpTryPrecision = document.querySelector('#try-precision-form');
if (formLpTryPrecision) {
  import('./partials/form-try-precision/index').then((page) => page.default(formLpTryPrecision));
}

// LandingPage Confirm Email
const lpConfirmEmail = document.querySelector('#lp-confirm-email');
if (lpConfirmEmail) {
  import(/* webpackChunkName: "lp-confirm-email" */
    './pages/lp-confirm-email/index'
  ).then((page) => page.default(lpConfirmEmail));
}

// LandingPage Email Confirmation
const emailConfirmation = document.querySelector('#email-confirmation');
if (emailConfirmation) {
  import(/* webpackChunkName: "email-confirmation" */
    './pages/email-confirmation/index'
  ).then((page) => page.default(emailConfirmation));
}

// Locator Page
const locator = document.querySelector('#locator');
if (locator) {
  import(/* webpackChunkName: "locator" */ './pages/locator/index').then((page) => page.default(locator));
}

// Locator Page
const selectLenses = document.querySelector('#select-lenses');
if (selectLenses) {
  import(/* webpackChunkName: "selectLenses" */ './pages/select-lenses/index').then((page) => page.default(selectLenses));
}

// Locator Page or /kachestvo-zreniya/katarakta/
const locatorClinics = document.querySelector('#locator-clinics');
if (locatorClinics) {
  import(/* webpackChunkName: "locatorClinics" */ './pages/locator-clinics/index').then((page) => page.default(locatorClinics));
}

// Search Page
const search = document.querySelector('#search-page');
if (search) {
  import('./pages/search/index').then((page) => page.default(search));
}

// Call Center
const callCenterLayout = document.querySelector('#call-center-layout');

if (callCenterLayout) {
  document.querySelector('html').classList.add('call-center-app');
  import(
    /* webpackChunkName: "call-center-app" */
    './components/call-center/index'
  ).then((partial) => {
    partial.default(callCenterLayout);
  });
}

// Order Precision One
const orderPrecisionOneLayout = document.querySelector('#order-precision-one-layout');

if (orderPrecisionOneLayout) {
  document.querySelector('html').classList.add('order-precision-one-app');
  import(
    /* webpackChunkName: "call-center-app" */
    './components/order-precision-one/index'
  ).then((partial) => {
    partial.default(orderPrecisionOneLayout);
  });
}

// Сalling Specialists
const callingSpecialistsLayout = document.querySelector('#calling-specialists');

if (callingSpecialistsLayout) {
  document.querySelector('html').classList.add('call-center-app');
  import(
    './components/calling-specialists/index'
  ).then((partial) => {
    partial.default(callingSpecialistsLayout);
  });
}

// Newbie popup
// const newbiePopup = document.querySelector('#modal-newbie');

// if (newbiePopup) {
//   import('./partials/newbie-popup').then((partial) => partial.default(newbiePopup));
// }

// Provision Application
const provisionApplication = document.querySelector('#provision-application');

if (provisionApplication) {
  import(
    /* webpackChunkName: "provision-application" */
    './components/ProvisionApplication'
  ).then((component) => component.default(provisionApplication));
}

const audioNews = document.querySelector('[data-pronews-audio]');

if (audioNews) {
  // eslint-disable-next-line no-unused-expressions
  import(
    /* webpackChunkName: "pronews-audio" */
    './components/pronews-audio'
  );
}

// Verified Email Popup
(() => {
  const emailVerificationSearchParams = new URLSearchParams(window.location.search);
  const val = emailVerificationSearchParams.has('emailConfirmation')
    && emailVerificationSearchParams.has('userId')
    && emailVerificationSearchParams.has('code');
  if (val) {
    import(
      /* webpackChunkName: "verified-email-popup" */
      './partials/verified-email-popup'
    ).then((partial) => partial.default());
  }
})();

// Policy modal
const PolicyModal = document.querySelector('#policy-modal');

if (PolicyModal && !window.location.href.includes('my-profile')) {
  import(
    /* webpackChunkName: "policy-modal" */
    './partials/policy-modal/index'
  ).then((partial) => partial.default(PolicyModal));
}

const ExpressTestApp = document.querySelector('#express-test-app');

if (ExpressTestApp) {
  import(
    /* webpackChunkName: "express-test-app" */
    './components/express-test/index'
  ).then((component) => component.default(ExpressTestApp));
}

// Lenses calculator LensesCalculator
const LensesCalculator = document.querySelector('#calculator-lenses');

if (LensesCalculator) {
  import(
    /* webpackChunkName: "lenses-calculator" */
    './redesign/pages/LensesCalculator'
  ).then((component) => component.default(LensesCalculator));
}

const TestDetailMaterial = document.querySelector('#test-detail-material');

if (TestDetailMaterial) {
  import(
    './components/TestDetailMaterial'
  ).then((component) => component.default(TestDetailMaterial));
}

const TestDetailMaterialUI = document.querySelector('#test-detail-material-ui');
if (TestDetailMaterialUI) {
  import(
    './redesign/components/vue/TestDetailMaterialUI'
  ).then((component) => component.default(TestDetailMaterialUI));
}

const ReviewDetailMaterialUI = document.querySelector('#review-detail-material');
if (ReviewDetailMaterialUI) {
  import(
    './redesign/components/vue/ReviewDetailMaterialUI'
  ).then((component) => component.default(ReviewDetailMaterialUI));
}

// Club / Invite Friend / Form
// const ClubInviteFriendForm = document.querySelector('#club-invite-friend-form');
//
// if (ClubInviteFriendForm) {
//   import(
//     /* webpackChunkName: "club-invite-friend-form" */
//     './pages/club/EmailInvite/index.js'
//   ).then((component) => component.default(ClubInviteFriendForm));
// }

// rating hcp detail page
const ratingMaterialAll = document.querySelectorAll('[data-detail-material-rating]');
if (ratingMaterialAll) {
  ratingMaterialAll.forEach((rating) => {
    import(
      /* webpackChunkName: "detail-material-rating" */
      './components/DetailMaterial/Rating/index'
    ).then((component) => component.default(rating));
  });
}

function fire() {
  initBannersAndArticleContent();
}

// Init StickySidebar, Banners, ArticleContent /
function initBannersAndArticleContent() {
  const elDetailPageMaterial = document.querySelector('[data-type-detail-material="article"]');
  const elUserDetailMaterial = document.querySelector('[data-status-user-detail-material]');
  const typeMaterial = (elUserDetailMaterial ? elUserDetailMaterial.getAttribute('data-type-detail-material') : '');
  let stickySidebarInst = {
    ok: false
  };

  // 1
  if (elDetailPageMaterial && elUserDetailMaterial && typeMaterial === 'article') {
    const isThereVerticalBannerContent = new BannerDetail();
    if (isThereVerticalBannerContent.ok) {
      stickySidebarInst = new StickySidebar().ok;
      isThereVerticalBannerContent.initVerticalBanners();
    }
    isThereVerticalBannerContent.initHorizontalBanners();
  }

  // 2
  const ArticleContentGeneratorInit = new ArticleContentGenerator();
  if (ArticleContentGeneratorInit.ok) {
    if (!stickySidebarInst.ok) {
      stickySidebarInst = new StickySidebar().ok;
    }
    ArticleContentGeneratorInit.init();
  }
}

const ytVideosWithTimecodes = document.querySelectorAll('[data-yt-video-with-timecodes]');
if (ytVideosWithTimecodes) {
  import(
    /* webpackChunkName: "time-code-list" */
    './pages/detail-materials/TimeCodeList'
  )
    .then(({ TimeCodeList }) => {
      new TimeCodeList(ytVideosWithTimecodes);
    });
}

const guidesBlockSlider = document.querySelectorAll('[data-r-guides-block-slider]');
if (guidesBlockSlider) {
  import(
    /* webpackChunkName: "r-guides-block-slider" */
    './redesign/bundles/product-landings/partials/r-guides-block-slider'
  )
    .then(({ initRGuidesBlockSlider }) => {
      initRGuidesBlockSlider();
    });
}

const stepsBlockSlider = document.querySelectorAll('[data-r-steps-block-slider]');
if (stepsBlockSlider) {
  import(
    /* webpackChunkName: "r-steps-block-slider" */
    './redesign/bundles/product-landings/partials/r-steps-block-slider'
  )
    .then(({ initRStepsBlockSlider }) => {
      initRStepsBlockSlider();
    });
}

const expandBlock = document.querySelectorAll('[data-expand-block-parent]');
if (expandBlock) {
  import(
    /* webpackChunkName: "r-expand-block" */
    './redesign/bundles/product-landings/partials/r-expand-block'
  )
    .then(({ RExpandBlock }) => {
      new RExpandBlock();
    });
}

const baseAccordionUI = document.querySelectorAll('[data-base-accordion-ui]');
if (baseAccordionUI) {
  import(
    /* webpackChunkName: "BaseAccordionUIRoot" */
    './redesign/components/partials/BaseAccordionUI/BaseAccordionUIRoot'
  )
    .then(({ BaseAccordionUIRoot }) => {
      new BaseAccordionUIRoot();
    });
}

const anchorLink = document.querySelectorAll('[data-r-anchor-link]');
if (anchorLink) {
  import(
    /* webpackChunkName: "r-anchor-handler" */
    './redesign/bundles/product-landings/partials/r-anchor-handler'
  )
    .then(({ initRAnchorHandler }) => {
      initRAnchorHandler();
    });
}

const tabsUI = document.querySelectorAll('[data-tabs-ui]');
if (tabsUI) {
  import(
    /* webpackChunkName: "tabsUI" */
    './redesign/components/partials/TabsUI'
  );
}

const rVideoYoutube = document.querySelectorAll('[data-r-video-youtube]');
if (rVideoYoutube) {
  import(
    /* webpackChunkName: "r-video-block" */
    './redesign/bundles/product-landings/partials/r-video-block'
  )
    .then(({ initRVideoYoutube }) => {
      initRVideoYoutube();
    });
}

// Slider products for vue
const productsSlider = document.querySelector('#products-slider');
if (productsSlider) {
  import(
    /* webpackChunkName: "products-slider" */
    './redesign/components/vue/ProductsSlider/index'
  ).then((component) => component.default(productsSlider));
}

// Slider products in page
const productsSliderInPage = document.querySelectorAll('[data-products-slider-ui]');
if (productsSliderInPage) {
  import(
    /* webpackChunkName: "products-slider-ui" */
    './redesign/components/partials/ProductsSliderUI'
  );
}

const galleriesImageProduct = document.querySelector('[data-gallery-image-product]');
if (galleriesImageProduct) {
  import(
    /* webpackChunkName: "gallery-image-product" */
    './redesign/components/partials/GalleryImageProductUI'
  );
}
