const tagsWrapper = document.querySelector('[data-specialists-pro-page-filters="tags"]');
const sections = document.querySelectorAll('[data-specialists-pro-page-filters="section"]');

const findEnabledTags = () => [...tagsWrapper.querySelectorAll('.tag__state:checked')];
const enabledTagsValues = () => findEnabledTags().map((tag) => tag.value);

const onTagsChange = () => {
  const values = enabledTagsValues();

  sections.forEach((section) => {
    const id = section.getAttribute('data-id');

    if (!values.length) {
      section.removeAttribute('hidden');
      return;
    }

    if (values.includes(id)) {
      section.removeAttribute('hidden');
    } else {
      section.setAttribute('hidden', '');
    }
  });
};

const init = () => {
  tagsWrapper.addEventListener('change', onTagsChange);
  onTagsChange();
};

if (tagsWrapper && sections.length) {
  init();
}
