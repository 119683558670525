const elements = document.querySelectorAll('[data-ltabs]');
/* eslint-disable */
function initTabs(el) {
  const controls = el.querySelector('[data-ltabs-controls]').querySelectorAll('button');
  const views = el.querySelectorAll('[data-ltabs-view]');

  const tabCLickHandler = (event) => {
    const index = event.currentTarget.dataset.ltabsControlIndex;
    controls.forEach((el) => el.classList.remove('active'));
    event.currentTarget.classList.add('active');
    views.forEach((el) => { el.hidden = el.dataset.ltabsViewIndex !== index });
  };

  controls.forEach((el, index) => {
    el.dataset.ltabsControlIndex = index;
    el.addEventListener('click', tabCLickHandler)
  });

  views.forEach((el, index) => {
    el.dataset.ltabsViewIndex = index;
  });
}
/* eslint-enable */
elements.forEach((el) => initTabs(el));
