import $ from 'jquery';

$('[data-club-privilege-slider-item]').each((index, item) => {
  const slider = $(item).find('[data-club-privilege-slider]');
  const nextArrow = $(item).find('[data-club-privilege-slider-next]');
  const prevArrow = $(item).find('[data-club-privilege-slider-prev]');
  const appendDots = $(item).find('[data-club-privilege-slider-dots]');

  slider.slick({
    slidesToShow   : 3,
    slidesToScroll : 3,

    nextArrow,
    prevArrow,
    appendDots,

    dots          : true,
    arrows        : true,
    variableWidth : false,
    infinite      : false,
    responsive    : [
      {
        breakpoint : 1440,
        settings   : {
          slidesToShow   : 2,
          slidesToScroll : 2,
          dots           : true,
          arrows         : false,
          variableWidth  : false,
          infinite       : false
        }
      },
      {
        breakpoint : 1024,
        settings   : {
          slidesToShow   : 2,
          slidesToScroll : 2,
          dots           : true,
          arrows         : false,
          variableWidth  : false,
          infinite       : false
        }
      },
      {
        breakpoint : 768,
        settings   : {
          slidesToShow   : 1,
          slidesToScroll : 1,
          dots           : true,
          arrows         : false,
          variableWidth  : true,
          infinite       : false
        }
      }
    ]
  });
});
