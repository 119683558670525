import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const cards = [...document.querySelectorAll('[data-myths-and-facts-cards]')];

function hideElem() {
  cards.forEach((item) => {
    item.classList.remove('is-active');
  });
}

cards.forEach((card) => {
  const cardOpen = card.querySelector('[data-myths-and-facts-cards-open]');
  const cardClose = card.querySelector('[data-myths-and-facts-cards-close]');
  const cardCloseMobile = card.querySelector('[data-myths-and-facts-cards-close-mobile]');
  const cardScrollBlock = card.querySelector('[data-myths-and-facts-cards-scroll]');

  cardOpen.addEventListener('click', () => {
    hideElem();
    card.classList.add('is-active');

    if (window.innerWidth < 768) {
      disableBodyScroll(cardScrollBlock);
    }
  });

  cardClose.addEventListener('click', () => {
    card.classList.remove('is-active');
    clearAllBodyScrollLocks();
  });

  cardCloseMobile.addEventListener('click', () => {
    card.classList.remove('is-active');
    clearAllBodyScrollLocks();
  });
});
