/*
 * sticky banner for articles detail
 */
export class StickySidebar {
  constructor() {
    this.ok = true;
    this.isSetupEventListeners = false;
    this.spacer = 18;
    this.headerElement = document.querySelector('[data-header-ui]');
    this.topFixedPosition = null;
    this.sidebar = document.querySelector('[data-detail-material-sidebar]');
    this.panelSticky = document.querySelector('[data-detail-material-sidebar-panel]');
    this.positionSticky = document.querySelector('[data-detail-material-sidebar-position]');

    this.elUserDetailMaterial = document.querySelector('[data-status-user-detail-material]');

    this.contentDetailMaterial = document.querySelector('[data-detail-material-content]');
    this.sidebarDetailMaterial = document.querySelector('[data-detail-material-sidebar]');
    this.breadcrumb = document.querySelector('.breadcrumb');

    if (this.panelSticky) {
      this.init();
    }
  }

  init() {
    // sticky banner - плавающий баннер
    if (!this.elUserDetailMaterial.classList.contains('is-sticky-banner')) {
      // Добавляем класс, чтобы выровнять breadcrumb с статьями с плавающим баннером
      // eslint-disable-next-line max-depth
      if (this.breadcrumb) {
        this.breadcrumb.classList.add('breadcrumb--detail-material-sticky-banner');
      }

      this.elUserDetailMaterial.classList.add('is-sticky-banner');
      if (this.contentDetailMaterial) {
        this.contentDetailMaterial.classList.add('content-with-sticky-banner');
      }
      if (this.sidebarDetailMaterial) {
        this.sidebarDetailMaterial.classList.remove('is-hidden');
      }

      this.initEvents();
    }
  }

  initEvents() {
    this.checkEventListeners();

    window.addEventListener('resize', () => {
      this.checkEventListeners();
    }, { passive: true });
  }

  getCoords(elem) {
    const box = elem.getBoundingClientRect();
    return {
      top  : box.top + window.pageYOffset,
      left : box.left + window.pageXOffset
    };
  }

  checkEventListeners() {
    if (window.matchMedia('(min-width: 1200px)').matches && !this.isSetupEventListeners) {
      this.setupEventListeners();
    }
    if (!window.matchMedia('(min-width: 1200px)').matches && this.isSetupEventListeners) {
      this.removeEventListeners();
    }
  }

  setupEventListeners() {
    window.addEventListener('resize', () => {
      this.initDesktop();
    }, { passive: true });

    window.addEventListener('scroll', () => {
      this.initDesktop();
    });

    this.isSetupEventListeners = true;
    this.initDesktop();
  }

  removeEventListeners() {
    window.removeEventListener('resize', () => {
      this.initDesktop();
    });

    window.removeEventListener('scroll', () => {
      this.initDesktop();
    });

    this.isSetupEventListeners = false;
    this.initMobile();
  }

  initDesktop() {
    this.initStickyPanel();
  }

  initMobile() {
    this.panelSticky.removeAttribute('style');
  }

  // eslint-disable-next-line max-statements
  initStickyPanel() {
    const panel = this.panelSticky;
    const topPanel = this.getCoords(this.positionSticky).top;
    const topContainer = this.getCoords(this.sidebar).top;
    const diff = topPanel - topContainer;

    const leftPanel = this.getCoords(this.positionSticky).left;
    const heightPanel = panel.offsetHeight;
    const heightContainer = this.sidebar.offsetHeight;

    this.topFixedPosition = (this.headerElement ? this.headerElement.offsetHeight : 124) + this.spacer;

    // доскроллили до стоп позиции
    if (this.topFixedPosition + window.pageYOffset + heightPanel >= topContainer + heightContainer) {
      panel.style.position = 'absolute';
      panel.style.top = `${heightContainer - heightPanel - diff}px`;
      panel.style.left = '0';
      panel.style.right = 'auto';
      panel.style.width = '100%';
    }

    // в интервале между старт позицией и стоп позицией
    if (this.positionSticky.getBoundingClientRect().top <= this.topFixedPosition
      && this.topFixedPosition + window.pageYOffset + heightPanel < topContainer + heightContainer) {
      panel.style.position = 'fixed';
      panel.style.top = `${this.topFixedPosition}px`;
      panel.style.left = `${leftPanel}px`;
      panel.style.right = 'auto';
      panel.style.width = `${this.positionSticky.offsetWidth}px`;
    }

    // выше старт позиции
    if (this.positionSticky.getBoundingClientRect().top > this.topFixedPosition) {
      panel.removeAttribute('style');
    }
  }
}

// window.addEventListener('DOMContentLoaded', () => {
//   const elDetailPageMaterial = document.querySelector('[data-type-detail-material="article"]');
//   const elUserDetailMaterial = document.querySelector('[data-status-user-detail-material]');
//   const typeMaterial = (elUserDetailMaterial ? elUserDetailMaterial.getAttribute('data-type-detail-material') : '');
//
//   if (elDetailPageMaterial && elUserDetailMaterial && typeMaterial === 'article') {
//     // sticky banner - плавающий баннер
//     new StickySidebar();
//   }
// });
