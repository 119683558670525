const __YOUTUBE_IFRAME_API_SCRIPT_TAG_ID = 'youtube-iframe-api-script-tag';

const playVideo = (player) => {
  const playButton = player.querySelector('[data-specialists-pro-page-video="play"]');
  playButton.setAttribute('hidden', '');

  const videoHolder = player.querySelector('[data-specialists-pro-page-video="video"]');
  videoHolder.innerHTML = '<div></div>';

  const videoEl = videoHolder.querySelector('div');

  videoHolder.classList.add('is-active');

  // eslint-disable-next-line no-undef
  new YT.Player(videoEl, {
    videoId : player.getAttribute('data-youtube-id'),
    events  : {
      onReady(event) {
        event.target.playVideo();
      }
    }
  });
};

document.body.addEventListener('click', (e) => {
  const isPlayButton = (
    e.target.getAttribute('data-specialists-pro-page-video') === 'play'
    || e.target.closest('[data-specialists-pro-page-video="play"]')
  );

  if (e.target && isPlayButton) {
    playVideo(e.target.closest('[data-specialists-pro-page-video="player"]'));
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const areThereYtVideos = document.querySelector('[data-specialists-pro-page-video="video"]');

  if (!areThereYtVideos) {
    return;
  }

  const isScriptLoaded = document.querySelector(`script#${__YOUTUBE_IFRAME_API_SCRIPT_TAG_ID}`);

  if (isScriptLoaded) {
    return;
  }

  const tag = document.createElement('script');

  tag.id = __YOUTUBE_IFRAME_API_SCRIPT_TAG_ID;
  tag.src = 'https://www.youtube.com/iframe_api';

  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
});
