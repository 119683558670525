import smoothscroll from 'smoothscroll-polyfill';
import { debounce } from 'lodash';

smoothscroll.polyfill();

function scrollToElAction(clickTarget, el) {
  let targetElId = null;
  let element = null;

  if (!el) {
    targetElId = clickTarget.getAttribute('href');
    element = document.querySelector(targetElId);
  } else {
    element = el;
  }

  if (element) {
    let elementPosition = element.getBoundingClientRect().top + window.scrollY;

    if (window.matchMedia('(min-width: 1024px)').matches) {
      const header = document.querySelector('[data-fixed-header-ui]');
      const headerHeight = header ? header.offsetHeight : 0;
      elementPosition -= headerHeight;
    }

    window.scrollTo({ top: elementPosition });
  }
}

/*
* Генерация содержания статей. Имеет 2 режима работы.
*
* 1) В блоке а атрибутом "data-detail-material-root" ищет все "data-content-parent-title" и "data-content-sub-title"
* где, каждый "data-content-parent-title" будет родителем а все последующие "data-content-sub-title"
* до след. "data-content-parent-title" его элементами.
*
* Статья должна иметь структуру где блок "data-content-parent-title" идет до его эл-в "data-content-sub-title"
*
* 2) В блоке а атрибутом "data-detail-material-root" ищет все "data-content-parent-title" и "data-content-sub-title"
* где, каждый "data-content-parent-title" будет родителем , если после него до следующего такого же элемента нет "data-content-sub-title"
* то не отрендерится выпадающее меню и этот "data-content-parent-title" станет якорем
*
* Разметка:
* - если одиночный заголовок
*   <div data-content-parent-title="Текст заголовка">
*     <h2>Текст заголовка</h2>
*   </div>
*
* - если заголовок c элементами
*   <h2 data-content-parent-title="Текст заголовка"></h2>
*   <div data-content-sub-title="Текст подзаголовка">
*     <h3>Текст подзаголовка</h3>
*   </div>
*
* либо если подблоки/блоки статей очень мелкие(для таких кейсов предусмотрен rootMargin: '0px 0px -65% 0px' в IntersectionObserver):
*
* * Разметка:
* - если одиночный заголовок
*   <h2 data-content-parent-title="Текст заголовка">Текст заголовка</h2>
*
* - если заголовок c элементами
*   <h2 data-content-parent-title="Текст заголовка"></h2>
*   <h3 data-content-sub-title="Текст подзаголовка">Текст подзаголовка</h3>
*   <h3 data-content-sub-title="Текст подзаголовка">Текст подзаголовка</h3>
*   ...
* */

export class ArticleContentGenerator {
  constructor() {
    this.ok = false;
    this.sidebarSelector = ['[data-detail-material-sidebar-panel]'];
    this.rootSelector = '[data-detail-material-root]';
    this.elementSelector = '[data-content-parent-title], [data-content-sub-title]';
    this.elements = null;
    this.content = document.querySelector(this.rootSelector);
    this.fileName = 'article-content-generator.js';
    this.insertedHtml = null;

    let nameArr = '';
    if (this.content) {
      const el = this.content.querySelector(this.sidebarSelector);
      nameArr = el ? el.getAttribute('data-detail-material-name-arrow') : '';
    }

    this.arrowSvg = nameArr && nameArr === 'r-arrow'
      // eslint-disable-next-line max-len
      ? '<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8,0.2c-0.3-0.3-0.8-0.3-1.1,0L8,7.1L1.3,0.2C1-0.1,0.5-0.1,0.2,0.2s-0.3,0.8,0,1.1l7.3,7.5c0.3,0.3,0.8,0.3,1.1,0 l7.3-7.5C16.1,1,16.1,0.5,15.8,0.2z" fill="currentColor"/></svg>'
      // eslint-disable-next-line max-len
      : '<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.907058 8.1688C1.38358 8.63182 2.14182 8.63223 2.61885 8.16973L7.5 3.43725L12.3812 8.16973C12.8582 8.63223 13.6164 8.63182 14.0929 8.1688C14.5896 7.6862 14.5896 6.8887 14.0929 6.4061L7.5 0L0.907058 6.4061C0.410377 6.8887 0.410377 7.6862 0.907058 8.1688Z" fill="currentColor"/></svg>';

    this.ok = !!this.content;
  }

  createEl(tagName, className, content, rootEl) {
    const blockName = 'article-content-list';
    const el = document.createElement(tagName);

    if (rootEl) {
      el.className = blockName;
    }

    if (className) {
      el.className = `${blockName}__${className}`;
    }

    if (content) {
      el.innerHTML = content; // textContent
    }

    return el;
  }

  /**
   * @description работает только в articles_2023.twig
   */
  init() {
    this.elements = this.content.querySelectorAll(this.elementSelector);

    if (!this.elements || !this.elements.length) {
      console.error(`${this.fileName}, не найдены заголовки`);
      return;
    }

    const result = {};

    let currentParentId = 0;
    let counter = 0;

    this.elements.forEach((el) => {
      counter += 1;
      const anchor = `detail_anchor_${counter}`;
      // eslint-disable-next-line no-param-reassign
      el.id = anchor;

      if ('contentParentTitle' in el.dataset) {
        currentParentId += 1;
        result[currentParentId] = {};
        result[currentParentId].children = [];
        result[currentParentId].parent = el.dataset.contentParentTitle;
        result[currentParentId].selector = `[data-content-parent-title="${el.dataset.contentParentTitle}"]`;
        result[currentParentId].anchor = anchor;
      }

      if ('contentSubTitle' in el.dataset) {
        result[currentParentId].children.push({
          text: el.dataset.contentSubTitle,
          anchor
        });
      }
    });

    // eslint-disable-next-line no-unused-vars,no-restricted-syntax
    for (const [_, value] of Object.entries(result)) {
      if (!value.children.length) {
        const elWithoutChildren = document.querySelector(value.selector);
        elWithoutChildren.dataset.articleSingleHeading = true;
      }
    }

    this.createHtml(result);
  }

  // eslint-disable-next-line max-statements
  createHtml(data) {
    if (!data) {
      console.error(`${this.fileName}, не переданы данные для создания html`);
      return;
    }

    const wrapper = this.createEl('div', null, null, true);
    wrapper.dataset.articleContentRoot = '';

    // eslint-disable-next-line no-unused-vars,no-restricted-syntax
    for (const [_, value] of Object.entries(data)) {
      const hasChildren = value.children.length;
      const block = this.createEl('div', 'el');
      const span = this.createEl('span', 'heading-inner', value.parent);

      const blockHeading = this.createEl(hasChildren ? 'span' : 'a', 'heading');
      if (!hasChildren) {
        blockHeading.href = `#${value.anchor}`;
      }

      blockHeading.appendChild(span);
      blockHeading.addEventListener('click', this.headingClickHandler);
      block.dataset.articleContentEl = '';

      if (hasChildren) {
        const span2 = this.createEl('span', 'heading-icon');
        span2.innerHTML = this.arrowSvg;
        blockHeading.appendChild(span2);
      }

      block.appendChild(blockHeading);

      if (hasChildren) {
        const listWrapper = this.createEl('div', 'list');
        value.children.forEach((el) => {
          const div = this.createEl('div', 'list-item');
          const a = this.createEl('a', null, el.text);
          a.href = `#${el.anchor}`;
          a.addEventListener('click', this.linkClickHandler);
          div.appendChild(a);
          listWrapper.appendChild(div);
        });

        block.appendChild(listWrapper);
      }

      wrapper.appendChild(block);
    }

    this.print(wrapper);
  }

  print(html) {
    this.insertedHtml = html;
    const el = this.content.querySelector(this.sidebarSelector);

    if (!el) {
      console.error(`${this.fileName}, не найден сайдбар`);
      return;
    }

    el.prepend(html);

    this.initObserver();
    this.initAnchorFromURL();
  }

  initAnchorFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const anchor = urlParams.get('anchor');
    const hash = window.location.hash ? window.location.hash.substring(1) : null;

    if (!anchor && !hash) {
      return;
    }

    const targetElement = document.getElementById(hash) || document.getElementById(`detail_anchor_${anchor}`);

    if (!targetElement) {
      return;
    }

    window.addEventListener('load', () => {
      setTimeout(() => {
        scrollToElAction(null, targetElement);
      }, 0);
    });
  }

  // eslint-disable-next-line max-statements
  headingClickHandler(e) {
    e.preventDefault();

    if (!this) {
      console.error(this.fileName, 'headingClickHandler');
      return;
    }

    const clickTarget = this;
    const root = clickTarget.closest('[data-article-content-root]');

    const currentBLock = clickTarget.closest('[data-article-content-el]');

    if (currentBLock && currentBLock.classList.contains('open')) {
      return;
    }

    const elements = root.querySelectorAll('[data-article-content-el]');
    elements.forEach((el) => el.classList.remove('open'));

    if (this.hasAttribute('href') && this.href) {
      scrollToElAction(clickTarget);
    }

    currentBLock.classList.add('open');
  }

  linkClickHandler(e) {
    e.preventDefault();

    if (!this) {
      console.error(this.fileName, 'linkClickHandler');
      return;
    }

    const clickTarget = this;
    scrollToElAction(clickTarget);
  }

  initObserver() {
    const timeout = 400;
    const debouncedFn1 = debounce((el) => {
      el.closest('[data-article-content-el]').querySelector('.article-content-list__heading').click();
    }, timeout);

    const debouncedFn2 = debounce(() => {
      this.insertedHtml
        .querySelectorAll('.article-content-list__el')
        .forEach((el) => el.classList.remove('open'));
    }, timeout);

    const cleanLinksAndHeadings = () => {
      this.insertedHtml
        .querySelectorAll('.article-content-list__list-item a, .article-content-list__heading')
        .forEach((el) => el.classList.remove('open'));
    };

    const callback = (entries) => {
      entries.forEach(({ isIntersecting, target }) => {
        const subT = isIntersecting && target.dataset.contentSubTitle;
        const singleH = isIntersecting && target.dataset.articleSingleHeading;

        if (subT) {
          cleanLinksAndHeadings();

          const elInMenu = this.insertedHtml.querySelector(`a[href="#${target.id}"]`);
          elInMenu.classList.add('open');

          debouncedFn2.cancel();

          debouncedFn1(elInMenu);
        }

        if (singleH) {
          debouncedFn1.cancel();

          debouncedFn2();

          cleanLinksAndHeadings();

          const elInMenu = this.insertedHtml.querySelector(`a[href="#${target.id}"]`);
          elInMenu.classList.add('open');
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px 0px -65% 0px'
    });

    this.elements.forEach((el) => observer.observe(el));
  }
}
