import { initVideoApi, initVKVideoApi } from '../../../utils/common';

const scrollToVideo = (video) => {
  if (!video) {
    return;
  }

  const header = document.querySelector('[data-fixed-header-ui]');
  let heightHeader = 0;

  if (header) {
    heightHeader = window.innerWidth >= 1024 ? header.getBoundingClientRect().height : 0;
  }

  // Определение расстояния от начала страницы до блока. 8 - это отступ сверху
  const top = window.scrollY + video.getBoundingClientRect().y - heightHeader - 8;
  // Перемотка
  window.scrollTo({ top, behavior: 'smooth' });
};

export function initRVideoBlock() {
  // * Получаем все элементы на странице, которые имеют атрибут data-r-video-block
  const elements = document.querySelectorAll('[data-r-video-block]');

  // * Создаем пустой массив для всех видео на странице
  const allVideos = [];

  // * Для каждого элемента на странице, который имеет атрибут data-r-video-block, мы ищем видео внутри него и добавляем его в массив allVideos
  elements.forEach((el) => {
    const video = el.querySelector('video');
    if (video) {
      allVideos.push(video);
    }
  });

  // * Добавляем обработчик события play для каждого видео на странице
  allVideos.forEach((video) => {
    video.addEventListener('play', (event) => {
      event.preventDefault();
      // * При воспроизведении видео мы перебираем все видео на странице,
      // * кроме текущего, и останавливаем их, если они уже воспроизводятся
      // eslint-disable-next-line max-nested-callbacks
      allVideos.forEach((v) => {
        if (v !== video) {
          if (v.played.length > 0) {
            v.pause();
          }
        }
      });
    });
  });

  // * Создаем функцию для остановки всех видео на странице
  const stopAllVideos = () => {
    allVideos.forEach((el) => {
      if (el.played.length > 0) {
        el.pause();
      }
    });
  };

  // * Добавляем обработчик клика для каждого элемента на странице, который имеет атрибут data-r-video-block-preview
  elements.forEach((el) => {
    const preview = el.querySelector('[data-r-video-block-preview]');
    if (preview) {
      // * При клике на элемент мы ищем видео внутри его родительского элемента и запускаем его,
      // * останавливая при этом все остальные видео на странице
      preview.addEventListener('click', (event) => {
        const { currentTarget } = event;
        const wrapper = currentTarget.closest('[data-r-video-block]');
        const video = wrapper.querySelector('video');

        currentTarget.style.opacity = 0;
        stopAllVideos();

        // eslint-disable-next-line max-nested-callbacks
        setTimeout(() => {
          currentTarget.style.display = 'none';
          video.play();
        }, 350); // * время анимации
      });
    }
  });
}

export function initRVideoYoutube(activeTimecode) {
  const youtubePlayers = {};

  // Встраиваем видео на страницу
  const playVideo = (player = null, onReadyCallback = null) => {
    const preview = player.querySelector('[data-r-video-youtube-preview]');
    // Отображаем iframe сразу при клике на картинку,
    // а не в момент старта видео (фикс для ютуба)
    preview.classList.add('open-video');

    // * Останавливаем все видео которые были запущены.
    Object.values(youtubePlayers).forEach((video) => {
      video.stopVideo();
    });

    const videoId = player.getAttribute('data-youtube-id');
    const videoHolder = player.querySelector('[data-r-video-youtube-video]');

    videoHolder.classList.add('init');
    videoHolder.innerHTML = '<div></div>';

    const videoEl = videoHolder.querySelector('div');

    // eslint-disable-next-line no-undef
    youtubePlayers[videoId] = new YT.Player(videoEl, {
      videoId,
      events: {
        onReady(event) {
          event.target.playVideo();

          if (onReadyCallback) {
            onReadyCallback(event);
          }
        }
      }
    });
  };

  const seekToTimecodeYoutube = (player, seconds) => {
    const videoId = player.getAttribute('data-youtube-id');

    if (youtubePlayers[videoId]) {
      youtubePlayers[videoId].seekTo(seconds, true);
      youtubePlayers[videoId].playVideo();
    } else {
      playVideo(player, () => {
        youtubePlayers[videoId].seekTo(seconds, true);
      });
    }

    const videoHolder = player.querySelector('[data-r-video-youtube-video]');
    scrollToVideo(videoHolder);
  };

  const setEventClickPreviewsVideo = (listVideos) => {
    listVideos.forEach((preview) => {
      preview.addEventListener('click', (e) => {
        playVideo(e.target.closest('[data-r-video-youtube]'));
      });
    });
  };

  window.addEventListener('load', () => {
    // При клике на превью - встраиваем видео на страницу
    const previewsVideo = document.querySelectorAll('[data-r-video-youtube-preview]');
    if (previewsVideo && previewsVideo.length) {
      // Подключаем апи ютуба
      initVideoApi();

      setEventClickPreviewsVideo(previewsVideo);
    }
  });

  // Для детальной страницы видео в разделе специалиста
  if (activeTimecode) {
    document.body.addEventListener('click', (e) => {
      if (e.target.closest('[data-r-video-youtube]') && e.target.getAttribute('data-video-detail-material') === 'timecode') {
        const timeValue = parseInt(e.target.getAttribute('data-value'), 10);

        seekToTimecodeYoutube(e.target.closest('[data-r-video-youtube]'), timeValue);
      }
    });
  }
}

export function initRVideoVK(activeTimecode) {
  const vkPlayers = {};

  // Встраиваем видео на страницу
  const playVideo = (player = null, seconds = 0) => {
    const preview = player.querySelector('[data-r-video-vk-preview]');
    // Отображаем iframe сразу при клике на картинку,
    // а не в момент старта видео (фикс для ютуба)
    preview.classList.add('open-video');

    // * Останавливаем все видео которые были запущены.
    Object.values(vkPlayers).forEach((video) => {
      video.seek(0);
    });

    const ownerVideoId = player.getAttribute('data-vk-owner-id');
    const videoId = player.getAttribute('data-vk-id');
    const videoHolder = player.querySelector('[data-r-video-vk-video]');

    videoHolder.classList.add('init');
    videoHolder.innerHTML = `<iframe
        src="https://vk.com/video_ext.php?oid=${ownerVideoId}&id=${videoId}&hd=2&js_api=1"
        allow="encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        frameborder="0"
        allowfullscreen
        ></iframe>`;

    const videoEl = videoHolder.querySelector('iframe');

    // eslint-disable-next-line no-undef
    vkPlayers[videoId] = new VK.VideoPlayer(videoEl);

    vkPlayers[videoId].on('inited', () => {
      vkPlayers[videoId].seek(seconds);
      vkPlayers[videoId].play();
    });
  };

  const seekToTimecode = (player, seconds) => {
    const videoId = player.getAttribute('data-vk-id');

    if (vkPlayers[videoId]) {
      vkPlayers[videoId].seek(seconds);
      vkPlayers[videoId].play();
    } else {
      playVideo(player, seconds);
    }

    const videoHolder = player.querySelector('[data-r-video-vk-video]');
    scrollToVideo(videoHolder);
  };

  const setEventClickPreviewsVideo = (listVideos) => {
    listVideos.forEach((preview) => {
      preview.addEventListener('click', (e) => {
        playVideo(e.target.closest('[data-r-video-vk]'));
      });
    });
  };

  window.addEventListener('load', () => {
    // При клике на превью - встраиваем видео на страницу
    const previewsVideo = document.querySelectorAll('[data-r-video-vk-preview]');
    if (previewsVideo && previewsVideo.length) {
      // Подключаем апи ВК видео
      initVKVideoApi();

      setEventClickPreviewsVideo(previewsVideo);
    }
  });

  // Для детальной страницы видео в разделе специалиста
  if (activeTimecode) {
    document.body.addEventListener('click', (e) => {
      if (e.target.closest('[data-r-video-vk]') && e.target.getAttribute('data-video-detail-material') === 'timecode') {
        const timeValue = parseInt(e.target.getAttribute('data-value'), 10);

        seekToTimecode(e.target.closest('[data-r-video-vk]'), timeValue);
      }
    });
  }
}
