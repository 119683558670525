const popup = document.querySelector('[data-thanks-precison]');
const popupClose = document.querySelector('[data-thanks-precison-close]');
const popupBackdrop = document.querySelector('[data-thanks-precison-backdrop]');
const clubInviteFriendLocator = document.querySelector('[data-club-invite-friend-locator]');
const anchors = document.querySelectorAll('[data-invite-friend-club-anchor]');

if (anchors.length) {
  anchors.forEach((el) => {
    el.addEventListener('click', function handler(e) {
      e.preventDefault();
      const target = document.querySelector(this.getAttribute('href'));

      if (!target) {
        return;
      }

      target.scrollIntoView({ behavior: 'smooth' });
    });
  });
}

if (popup) {
  if (window.location.search.includes('thanks-precision')) {
    popup.classList.add('is-active');
  }

  popupClose.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();

    popup.classList.remove('is-active');
  });

  popupBackdrop.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();

    popup.classList.remove('is-active');
  });
}

if (clubInviteFriendLocator) {
  import(/* webpackChunkName: "club-invite-friend-locator" */
    './locator/index'
  ).then((page) => page.default(clubInviteFriendLocator));
}
