const questionItemText = document.querySelectorAll('.questions .question-item .text');

questionItemText.forEach((el) => {
  el.addEventListener('click', (e) => {
    const { target } = e;
    const textBlock = target.closest('.text');
    textBlock.classList.toggle('active');
    const collapseText = textBlock.querySelector('.collapse-text');
    const arrow = textBlock.querySelector('.arrow');
    if (collapseText) {
      collapseText.classList.toggle('opened');
    }
    if (arrow) {
      arrow.classList.toggle('active');
    }
  });
});
