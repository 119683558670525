const cards = document.querySelectorAll('[data-card-mify-i-fakty]');

if (cards && cards.length) {
  const closeAllCardsElem = () => {
    cards.forEach((el) => {
      el.classList.remove('open-fact');
    });
  };

  cards.forEach((card) => {
    const btnOpenCard = card.querySelector('[data-open-card-mify-i-fakty]');
    const btnCloseCard = card.querySelector('[data-close-card-mify-i-fakty]');

    btnOpenCard.addEventListener('click', () => {
      // Закрываем все открытые карточки
      closeAllCardsElem();

      // Открываем текущую карточку
      card.classList.add('open-fact');
    });

    btnCloseCard.addEventListener('click', () => {
      card.classList.remove('open-fact');
    });
  });
}
