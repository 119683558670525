/*
 * sticky banner for articles detail
 */
export class BannerDetail {
  constructor() {
    this.ok = false;
    this.contentStickyBanner = document.querySelector('[data-detail-material-content-banner-sticky]');

    if (this.contentStickyBanner) {
      this.innerHTMLStickyBanner = this.contentStickyBanner.innerHTML;

      this.ok = !!(this.innerHTMLStickyBanner.indexOf('<img ') >= 0
        || this.innerHTMLStickyBanner.indexOf('<a ') >= 0);
    }
  }

  initVerticalBanners() {
    const panelSidebarDetailMaterial = document.querySelector('[data-detail-material-sidebar-panel-banner]');
    // добавим banner внутрь sidebarDetailMaterial
    panelSidebarDetailMaterial.innerHTML = this.innerHTMLStickyBanner;
    this.contentStickyBanner.innerHTML = '';
  }

  initHorizontalBanners() {
    const elUserDetailMaterial = document.querySelector('[data-status-user-detail-material]');
    const contentDetailMaterial = document.querySelector('[data-detail-material-content]');
    const innerBanners = document.querySelectorAll('[data-detail-material-content-banner]');

    // баннеры внутри текста статей
    if (innerBanners && !elUserDetailMaterial.classList.contains('is-add-banners')) {
      innerBanners.forEach((item) => {
        const banner = item;
        const view = banner.getAttribute('data-detail-material-content-banner');
        const containerBanner = (view && contentDetailMaterial ? contentDetailMaterial.querySelector(`[data-banner="${view}"]`) : false);

        if (containerBanner) {
          containerBanner.innerHTML = banner.innerHTML;
          banner.innerHTML = '';
        }
      });

      elUserDetailMaterial.classList.add('is-add-banners');
    }
  }
}
