const btnsScroll = document.querySelectorAll('[data-btn-scroll]');

if (btnsScroll && btnsScroll.length) {
  btnsScroll.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      event.preventDefault();

      const contentScroll = document.querySelector(`[data-content-scroll="${btn.getAttribute('data-btn-scroll')}"]`);
      const isMobile = window.innerWidth < 1200;

      if (contentScroll) {
        const top = isMobile
          ? contentScroll.offsetTop - document.querySelector('[data-fixed-header-ui]').offsetHeight - 20
          : contentScroll.offsetTop - 20;

        window.scrollTo({ top, left: 0, behavior: 'smooth' });
      }
    });
  });
}
